.setting {
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.option {
  display: flex;
  align-items: center;

  height: 200px;
}
.option1 {
  display: flex;
  align-items: center;
  height: 100px;
}
h5 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
}
.setting-menu > button {
  flex-shrink: 1;
  border-radius: 50%;
  margin-left: 10px;
  height: 80px;
  width: 80px;
}
.setting-menu > button:hover {
  zoom: 1.2;
  transform: rotate(360deg);
  transition: 1s;
  border: 2px white solid;
}
.plate {
  position: absolute;
  color: white;
  font-size: 20px;
  background-color: #151c32;
  border-radius: 10px;
  left: 10%;
  top: 30%;
  transform: translateX(-30px) rotate(10deg);
  border: 1px grey solid;
  padding: 10px;
  box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  z-index: 2;
  font-weight: 500;
  filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));
}
.plate:hover {
  animation: wobble-hor-top 1s both;
  cursor: pointer;
  transition: 2s;
}
.plate:before {
  position: absolute;
  content: ".";
  height: 10px;
  width: 10px;
  color: grey;
  margin-left: 40%;
  font-size: 80px;
  margin-top: -150px;
}
.plate:after {
  content: "";
  width: 50px;
  height: 70px;
  border: 1px #151c32 solid;
  margin-left: -100px;
  position: absolute;
  margin-top: -69px;
  border-radius: 150px;
  z-index: 1;
}

@keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

.trash {
  background: #151c32;
  width: 180px;
  height: 300px;
  display: inline-block;
  margin: 0 auto;
  position: relative;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  top: 100px;
  right: -500px;
  filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));

  box-shadow: inset 0 0 1px #000000;
  z-index: 2;
}
.trash:after {
  content: "";
  bottom: 0px;
  width: 100%;
  left: 50%;
  margin-left: -40%;
  -webkit-transform: perspective(300px) rotateY(40deg) rotateX(50deg);
  -moz-transform: perspective(300px) rotateY(40deg) rotateX(50deg);
  transform: perspective(300px) rotateY(40deg) rotateX(50deg);
}

.blackhole {
  position: absolute;
  height: 20px;
  width: 100%;
  background: black;
  border-radius: 50%;
  top: -10px;

  z-index: -1;
}
.pokrywka {
  position: absolute;
  height: 29px;
  background: #151c32;
  top: -19px;
  left: -10px;
  right: -10px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.pokrywka:after {
  content: "";
  position: absolute;
  height: 17px;
  background: #151c32;
  width: 160px;
  top: -15px;
  left: 20px;
  right: 30px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.pokrywka:before {
  content: "";
  position: absolute;
  height: 20px;
  background: #1c2542;
  width: 20px;
  top: -28px;
  left: 90px;
  right: 80px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
  box-shadow: inset 0 0 1px #000000;
}
.trash:hover .pokrywka {
  transform: rotate(-95deg);
  transition: transform 250ms;
  animation-play-state: smell running;
}

.paski {
  position: relative;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  margin: 25px auto;
  border-radius: 5px;
  box-shadow: inset 0 0 5px #1a223d;
}
.paski:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  border-radius: 5px;
  left: -50px;
  box-shadow: inset 0 0 5px #1a223d;
}
.paski:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  border-radius: 5px;
  right: -50px;
  box-shadow: inset 0 0 5px #1a223d;
}

#smells {
  display: none;
  border-radius: 50%;

  animation: smell 5s ease-in-out infinite;
  background-image: -webkit-radial-gradient(
    ellipse at center center,
    rgba(65, 64, 64, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: -webkit-radial-gradient(
    center center,
    ellipse,
    rgba(33, 33, 33, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: radial-gradient(
    ellipse at center center,
    rgba(4, 51, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  position: absolute;
  top: -45%;
  left: -30%;
  width: 300px;
  height: 300px;
  z-index: -2;
  animation-play-state: paused;
}
#smells:before {
  border-radius: 50%;
  -webkit-transform: scale(1.6);
  transform: scale(2.6);
  background-image: radial-gradient(
    ellipse at center center,
    rgba(33, 33, 33, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 180px;
  height: 100px;
  z-index: -5;
}

@keyframes smell {
  0% {
    transform: scale(0.5) translateY(0);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5) translateY(-300px);
    opacity: 0;
  }
}
.fly {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 20%;
  animation: fly 10s ease-in-out both;
  transition: transform 250ms;
  transform: rotate(90deg);
  transform-origin: 100% 100%;

  z-index: 90;
}
.fly:before {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly:after {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly:hover {
  cursor: pointer;
  animation-play-state: paused;
}

@keyframes fly {
  0% {
    transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  10% {
    transform: rotate(-45deg) translateY(-10px);
    opacity: 1;
  }
  20% {
    transform: rotate(-53deg) translateY(-10px);
    opacity: 1;
  }
  30% {
    transform: rotate(-51deg) translateY(-10px);
    opacity: 1;
  }
  40% {
    transform: rotate(-48deg) translateY(-10px);
    opacity: 1;
  }
  50% {
    transform: rotate(-42deg) translateY(-10px);
    opacity: 1;
  }
  60% {
    transform: rotate(-52deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    transform: rotate(-80deg) translateY(-2000px);
    opacity: 1;
  }
}

.fly1 {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 10%;
  animation: fly1 1s ease-in-out both;
  transition: transform 250ms;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  animation-play-state: paused;

  z-index: 2;
}
.fly1:before {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly1:after {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly1:hover {
  cursor: pointer;
}

@keyframes fly1 {
  0% {
    transform: rotate(-90deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    transform: rotate(-90deg) translateY(-400px);
    opacity: 1;
  }
}
.fly-hole {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 20%;

  transition: transform 250ms;
  transform: rotate(90deg);
  transform-origin: 10% 100%;

  z-index: 90;
}
.fly-hole:before {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly-hole:after {
  content: "";
  height: 8px;
  width: 5px;
  transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly-hole:hover {
  cursor: pointer;
  animation-play-state: paused;
}

@keyframes fly-hole {
  0% {
    transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  70% {
    transform: rotate(90deg) translateY(-250px);
    opacity: 1;
  }
}
