body {
  margin-top: 0px;
  height: 100vh;
  width: 100vw;
  color: white;
}

@font-face {
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/terminal_copy.ttf);
  font-family: "Terminal";
}
@font-face {
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/sys.ttf)
    format("truetype");
  font-family: "Sys";
}

$secondary: #1b2838;
$screen-background: #121010;

$color: lime;
$font-family: "Terminal";
$font-size: 20px;

@mixin pseudo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
}

@mixin center {
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate3d(-50%, -56%, 0);
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-text {
  animation: 5s ease 2000ms normal none infinite running glitch;
  filter: blur(1px);
  animation: jerkup 100ms infinite;
}
.pause {
  cursor: pointer;
  z-index: 2;
  pointer-events: all;
  display: flex;
  justify-content: center;
}
.h5 {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 30px;
}
.game-board-paddle--full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}
.gameover {
  position: absolute;
  color: lime;
  font-size: 80px;
  z-index: 3;
  cursor: pointer;
  filter: blur();
  animation: jerkup 100ms infinite;
  user-select: none;
}
.full {
  position: absolute;
  z-index: 99;
  right: 50px;
  top: 80px;
  font-size: 30px;
  background: black;
  color: white;
  opacity: 0.7;
}

.menu-text:last-child {
  text-align: right;
}
.screen::before {
  background: transparent linear-gradient(to bottom, #85908c 0%, #323431 100%)
    repeat scroll 0 0;
  content: "";
  @include center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.scanlines .overlay {
  height: 95%;
  left: 0;
  position: absolute;

  width: 100%;
  z-index: 1;
  @include flexCenter;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("./crt.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
  }
  &::before {
    @include pseudo;
    background: linear-gradient(
        transparentize($screen-background, 1) 50%,
        transparentize(darken($screen-background, 10), 0.75) 50%
      ),
      linear-gradient(
        90deg,
        transparentize(#ff0000, 0.94),
        transparentize(#00ff00, 0.98),
        transparentize(#0000ff, 0.94)
      );
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    //pointer-events: none;
  }
}

.menu {
  opacity: 1;
  background-color: #0069ff;
  box-sizing: border-box;
  font-family: "Sys";
  font-size: 40px;
  height: auto;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 #000000, -1px -1px 0 #000000, 1px -1px 0 #000000,
    -1px 1px 0 #000000;
  color: #fff;

  header {
    color: #e7e05c;
    margin-bottom: 10px;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
  }

  footer {
    background-color: #2afd72;
    margin-top: 10px;
    padding: 5px 15px;
    animation: 5s ease 2000ms normal none infinite running glitch;
    filter: blur(1px);

    &::after {
      clear: both;
      content: " ";
      display: table;
    }

    .key {
      float: left;
      width: 50%;

      span {
        background-color: #0069ff;
        border: 6px solid #e7e05c;
        border-radius: 6px;
        display: inline-block;
        height: 34px;
        line-height: 36px;
        padding-left: 6px;
      }
    }
    .key:last-child {
      text-align: right;
    }
  }
}

canvas {
  cursor: none;
  z-index: 2;
  filter: blur(1px);
  animation: jerkup 100ms infinite;
}
$delay: 2000ms;

.text {
  color: lime !important;
  opacity: 1;
  filter: blur(0.5px);
  font-family: Sys;
  font-size: 6vmin;
  left: 5%;
  position: absolute;
  text-shadow: 0 0 3px lime;
  top: 10%;
  width: 200px;
}

.on {
  .text,
  .menu {
    opacity: 1;
    transition: opacity 10ms ease 2000ms;
  }
}

@keyframes on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(4);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(4);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}

@keyframes off {
  0% {
    transform: scale(1, 1);
    filter: brightness(1);
  }
  40% {
    transform: scale(1, 0.005);
    filter: brightness(100);
  }
  70% {
    transform: scale(1, 0.005);
  }
  90% {
    transform: scale(0.005, 0.005);
  }
  100% {
    transform: scale(0, 0);
  }
}

@keyframes blur {
  0% {
    filter: blur(1px);
    opacity: 0.8;
  }
  50% {
    filter: blur(1px);
    opacity: 1;
  }
  100% {
    filter: blur(1px);
    opacity: 0.8;
  }
}
@keyframes jerk {
  50% {
    transform: translateX(1px);
  }
  51% {
    transform: translateX(0);
  }
}
@keyframes jerkup {
  0% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.text span:nth-child(2) {
  animation: jerkup 1s infinite;
}
.text span:nth-child(3) {
  animation: glitch1 1s infinite;
}
@keyframes glitch1 {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  31% {
    transform: translateX(10px);
  }
  32% {
    transform: translateX(0);
  }
  98% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}
.text span:nth-child(2) {
  animation: glitch2 1s infinite;
}
@keyframes glitch2 {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  31% {
    transform: translateX(-10px);
  }
  32% {
    transform: translateX(0);
  }
  98% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
.game-field {
  display: flex;
  justify-content: center;
  align-items: center;
  //filter: blur(1px);
  //animation: jerkup 100ms infinite;
}

.overlay .text {
  animation: 5s ease 2000ms normal none infinite running glitch;
}
@keyframes glitch {
  30% {
  }
  40% {
    opacity: 1;
    transform: scale(1, 1);
    transform: skew(0, 0);
  }
  41% {
    opacity: 0.8;
    transform: scale(1, 1.2);
    transform: skew(80deg, 0);
  }
  42% {
    opacity: 0.8;
    transform: scale(1, 1.2);
    transform: skew(-50deg, 0);
  }
  43% {
    opacity: 1;
    transform: scale(1, 1);
    transform: skew(0, 0);
  }
  65% {
  }
}
