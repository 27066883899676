@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navi {
  background-color: white;
  border-radius: 12px;
  margin-top: -50px;
  height: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 99;
  opacity: 1;
  position: fixed;
  left: 40%;
  z-index: 2;
}
.navi:hover {
  margin-top: 0px;
}
.navi:after {
  content: "menu";
  color: white;
  height: 80px;
  width: 80px;
  background-color: #0e3d58;
  position: relative;
  margin-top: 60px;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 800;
  z-index: 95;
  border-radius: 10px;

  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 12vmin;
  width: 15vmin;
  -moz-border-radius: 2vmin 0 0 16vmin;
  border-left: 2vmin solid rgb(255, 255, 255);
  border-bottom: 2vmin solid #0e3d58;
  border-radius: 2vmin 0 0 16vmin;
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: 22%;
  top: -22%;
  position: absolute;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;

  overflow: hidden;
}
.navi:hover::after {
  height: 12vmin;
  width: 15vmin;
  -moz-border-radius: 2vmin 0 0 16vmin;
  border-left: 2vmin solid rgb(255, 255, 255);
  border-bottom: 2vmin solid #0e3d58;
  border-radius: 6vmin 2vmin 0 19vmin;
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: 22%;
  top: -22%;
  position: absolute;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

#root {
  background-image: url(/static/media/backbrick.53e8bb1f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.nav-link {
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 900;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.nav-link:hover {
  color: #0e3d58;
}
.container {
  margin: auto;
  max-width: 960px;
}
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fly2 {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 10%;
  -webkit-animation: fly2 1s ease-in-out both;
          animation: fly2 1s ease-in-out both;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 40% 100%;
          transform-origin: 40% 100%;
  z-index: 10;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  cursor: pointer; }

.fly2:before {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5; }

.fly2:after {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5; }

.fly2:hover {
  cursor: pointer;
  -webkit-transition: 2s;
  transition: 2s; }

@-webkit-keyframes fly2 {
  0% {
    -webkit-transform: rotate(-45deg) translateY(0px);
            transform: rotate(-45deg) translateY(0px);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(45deg) translateY(0px);
            transform: rotate(45deg) translateY(0px);
    opacity: 1; } }

@keyframes fly2 {
  0% {
    -webkit-transform: rotate(-45deg) translateY(0px);
            transform: rotate(-45deg) translateY(0px);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(45deg) translateY(0px);
            transform: rotate(45deg) translateY(0px);
    opacity: 1; } }

.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.home > h1 {
  font-size: 80px; }

@font-face {
  font-family: "neontubes";
  src: url("https://bitbucket.org/kennethjensen/webfonts/raw/fc13c1cb430a0e9462da56fe3f421ff7af72db71/neontubes/neontubes-webfont.eot");
  src: url("https://bitbucket.org/kennethjensen/webfonts/raw/fc13c1cb430a0e9462da56fe3f421ff7af72db71/neontubes/neontubes-webfont.eot?#iefix") format("embedded-opentype"), url("https://bitbucket.org/kennethjensen/webfonts/raw/fc13c1cb430a0e9462da56fe3f421ff7af72db71/neontubes/neontubes-webfont.woff2") format("woff2"), url("https://bitbucket.org/kennethjensen/webfonts/raw/fc13c1cb430a0e9462da56fe3f421ff7af72db71/neontubes/neontubes-webfont.woff") format("woff"), url("https://bitbucket.org/kennethjensen/webfonts/raw/fc13c1cb430a0e9462da56fe3f421ff7af72db71/neontubes/neontubes-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #030c1c; }

.main {
  display: block;
  position: absolute;
  top: 50%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50vw;
  height: auto;
  font-family: "neontubes";
  font-size: 16vw; }

.main span {
  display: block;
  position: relative;
  -webkit-transform: translateZ(0) translate3D(0, 0, 0);
  transform: translateZ(0) translate3D(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity; }

.main .title {
  font-size: 5vw;
  color: #ffd9e2;
  text-shadow: 0 0 0 transparent, 0 0 10px #ff003c, 0 0 20px rgba(255, 0, 60, 0.5), 0 0 40px #ff003c, 0 0 100px #ff003c, 0 0 200px #ff003c, 0 0 300px #ff003c, 0 0 500px #ff003c, 0 0 1000px #ff003c;
  -webkit-animation: blink 4s infinite alternate;
  animation: blink 4s infinite alternate;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.main .desc {
  font-size: 3vw;
  color: #d4eaff;
  text-shadow: 0 0 0 transparent, 0 0 10px #2695ff, 0 0 20px rgba(38, 149, 255, 0.5), 0 0 40px #2695ff, 0 0 100px #2695ff, 0 0 200px #2695ff, 0 0 300px #2695ff, 0 0 500px #2695ff;
  -webkit-animation: buzz 0.01s infinite alternate;
  animation: buzz 0.01s infinite alternate;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

@-webkit-keyframes buzz {
  70% {
    opacity: 0.8; } }

@keyframes buzz {
  70% {
    opacity: 0.8; } }

@-webkit-keyframes blink {
  40% {
    opacity: 1; }
  42% {
    opacity: 0.8; }
  43% {
    opacity: 1; }
  45% {
    opacity: 0.2; }
  46% {
    opacity: 1; } }

@keyframes blink {
  40% {
    opacity: 1; }
  42% {
    opacity: 0.8; }
  43% {
    opacity: 1; }
  45% {
    opacity: 0.2; }
  46% {
    opacity: 1; } }

@media screen and (min-width: 1000px) {
  .main {
    width: 400px;
    font-size: 150px; }
  .main .socod {
    font-size: 103px; } }

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: lighter; }

@media only screen and (max-width: 2200px) {
  .message {
    top: 45vmin; }
  .message h2 {
    font-size: 7vmin; }
  .meow-background {
    width: 50%; }
  .dogmenu li {
    float: left; } }

@media only screen and (max-width: 900px) {
  .message {
    top: 47vmin; }
  .message h2 {
    font-size: 5.5vmin; }
  .meow {
    left: 32%;
    top: 40%; }
  .meow-background {
    width: 100%; }
  .dogmenu li {
    float: none; }
  .dogmenu a {
    display: none;
    font: bold 10px sans-serif; } }

@media only screen and (max-width: 480px) {
  .message {
    top: 65 vmin; }
  .message h1 {
    margin-top: vmin;
    font-size: 20vmin; }
  .message h2 {
    font-size: 7vmin; }
  .meow {
    left: 27%;
    top: 40%; }
  .meow-background {
    width: 100%; }
  .dogmenu li {
    float: none; }
  .dogmenu a {
    display: none;
    font: bold 10px sans-serif; } }

.blue-bg {
  background-color: #55aadf;
  overflow: hidden; }

.message {
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%; }

.message h1 {
  font-family: ultra;
  color: #f8dc93;
  margin-bottom: -7vmin; }

.message h2 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 5vmin;
  margin-top: 5vmin; }

.message p {
  font-size: 3vmin;
  color: #b3d6ec; }

.cat {
  height: 16vmin;
  width: 30vmin;
  left: 50%;
  margin-top: 85vh;
  margin-left: 80vh;
  -webkit-transform: translateY(-70%) translateX(-50%);
  transform: translateY(-70%) translateX(-50%);
  border-radius: 5% 32% 15% 65%;
  top: -17vmin;
  z-index: 10; }

.cat-inner {
  height: 16vmin;
  width: 28vmin;
  background-color: #0e3d58;
  position: absolute;
  -webkit-transform: translateY(-70%) translateX(-50%) rotate(28deg);
  transform: translateY(-70%) translateX(-50%) rotate(28deg);
  border-radius: 5% 32% 35% 65%;
  left: 48%;
  bottom: -70%;
  -webkit-transform-origin: 20% 30%;
          transform-origin: 20% 30%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat:hover .cat-inner {
  height: 16vmin;
  width: 28vmin;
  background-color: #0e3d58;
  position: absolute;
  -webkit-transform: translateY(-70%) translateX(-50%) rotate(0deg);
  transform: translateY(-70%) translateX(-50%) rotate(0deg);
  border-radius: 5% 32% 35% 65%;
  left: 46%;
  bottom: -70%;
  -webkit-transform-origin: 20% 30%;
          transform-origin: 20% 30%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-head {
  position: absolute;
  height: 75%;
  width: 52%;
  background-color: #0e3d58;
  left: -22%;
  top: -55%;
  border-radius: 45% 45% 55% 55%; }

.cat-ear {
  position: absolute;
  background-color: #0e3d58;
  text-align: left;
  left: 24%;
  top: 3%;
  -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
  -webkit-animation: cattwitch 5s ease-in-out 2s infinite;
  animation: cattwitch 5s ease-in-out 2s infinite;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-ear:before,
.cat-ear:after {
  content: "";
  position: absolute;
  background-color: inherit; }

.cat-ear,
.cat-ear:before,
.cat-ear:after {
  width: 8vmin;
  height: 8vmin;
  border-top-right-radius: 30%; }

.cat-ear:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }

.cat-ear:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }

.cat-ear2 {
  position: absolute;
  background-color: #0e3d58;
  text-align: left;
  left: 37%;
  top: 19%;
  -webkit-transform: rotate(108deg) skewX(-30deg) scale(1, 0.566);
  transform: rotate(108deg) skewX(-30deg) scale(1, 0.566);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.cat-ear2:before,
.cat-ear2:after {
  content: "";
  position: absolute;
  background-color: inherit; }

.cat-ear2,
.cat-ear2:before,
.cat-ear2:after {
  width: 8vmin;
  height: 8vmin;
  border-top-right-radius: 30%; }

.cat-ear2:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }

.cat-ear2:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }

.cat-nose {
  position: absolute;
  background-color: #e2bad6;
  width: 15%;
  height: 20%;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 46%; }

.cat-nose:after {
  content: "";
  position: absolute;
  background-color: #e2bad6;
  width: 0vmin;
  height: 1vmin;
  border: 1px solid #000;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%; }

.cat-mouth {
  background-color: transparent;
  border-top: 0.3vmin solid #000;
  border-right: 0.3vmin solid #000;
  border-left: 0.3vmin solid #000;
  height: 1.5vmin;
  width: 3vmin;
  border-radius: 4vmin 4vmin 0px 0px;
  position: absolute;
  bottom: 13%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden; }

.cat-meow {
  background-color: #000;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-left: 2px solid #000;
  height: 1.5vmin;
  width: 3vmin;
  border-radius: 4vmin 4vmin 0px 0px;
  position: absolute;
  bottom: 120%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat:hover .cat-meow {
  -webkit-animation: meow 1s ease-in-out 6s 1;
  animation: meow 1s ease-in-out 6s 1; }

.cat-leg {
  border-top: 18vmin solid #092738;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-9deg);
  transform: rotate(-9deg);
  position: absolute;
  top: 45%;
  left: 6%;
  z-index: -1; }

.cat-foot {
  height: 2vmin;
  width: 5vmin;
  background-color: #828282;
  position: absolute;
  bottom: -63%;
  left: 10.5%;
  border-radius: 90% 0% 20% 20%; }

.cat-leg-front {
  border-top: 18vmin solid #0e3d58;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
  position: absolute;
  top: 39%;
  left: 18%;
  z-index: -1; }

.cat-foot-front {
  height: 2vmin;
  width: 5vmin;
  background-color: #bfbfbf;
  position: absolute;
  bottom: -63%;
  left: 19%;
  border-radius: 90% 0% 20% 20%; }

.cat-hind-leg {
  height: 14vmin;
  width: 10vmin;
  border-radius: 10vmin 0 3vmin 10vmin;
  position: absolute;
  background-color: #0e3d58;
  border-left: 1px solid #092738;
  right: 16%;
  bottom: -62%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-hind-leg2 {
  height: 14vmin;
  width: 10vmin;
  border-radius: 10vmin 0 3vmin 10vmin;
  position: absolute;
  background-color: #092738;
  border-left: 1px solid #092738;
  right: 25%;
  bottom: -62%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1; }

.cat-hind-foot {
  height: 2.5vmin;
  width: 5vmin;
  position: absolute;
  background-color: #0e3d58;
  border-radius: 30% 20% 20% 30%;
  right: 20%;
  bottom: -63%;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-hind-foot2 {
  height: 2.5vmin;
  width: 5vmin;
  position: absolute;
  background-color: #0e3d58;
  border-radius: 30% 20% 20% 30%;
  right: 22%;
  bottom: -63%;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1; }

.cat-hind-paw {
  height: 2.5vmin;
  width: 4.7vmin;
  position: absolute;
  background-color: #0e3d58;
  border-radius: 70% 0% 0% 0%;
  right: 32.5%;
  bottom: -63%; }

.cat-hind-paw2 {
  height: 2.5vmin;
  width: 4.7vmin;
  position: absolute;
  background-color: #092738;
  border-radius: 70% 0% 0% 0%;
  right: 39.5%;
  bottom: -63%;
  z-index: -1; }

.cat-hind-leg-top {
  border-top: 9vmin solid #0e3d58;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  position: absolute;
  top: 109%;
  right: 24%;
  z-index: 5;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-hind-leg-top2 {
  border-top: 9vmin solid #092738;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  position: absolute;
  top: 109%;
  right: 30%;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat:hover .cat-hind-leg {
  height: 15vmin;
  width: 10vmin;
  border-radius: 10vmin 4vmin 0 10vmin;
  position: absolute;
  background-color: #0e3d58;
  border-left: 1px solid #092738;
  -webkit-transform: rotate(24deg);
  transform: rotate(24deg);
  right: 6%;
  bottom: -7%;
  -webkit-transform-origin: 20% 30%;
          transform-origin: 20% 30%; }

.cat:hover .cat-hind-leg2 {
  height: 15vmin;
  width: 10vmin;
  border-radius: 10vmin 4vmin 0 10vmin;
  position: absolute;
  background-color: #092738;
  border-left: 1px solid #092738;
  -webkit-transform: rotate(24deg);
  transform: rotate(24deg);
  right: 15%;
  bottom: -7%;
  -webkit-transform-origin: 20% 30%;
          transform-origin: 20% 30%; }

.cat:hover .cat-hind-foot {
  height: 2.7vmin;
  width: 7vmin;
  position: absolute;
  background-color: #0e3d58;
  border-radius: 0% 0% 0% 0%;
  right: 15%;
  bottom: -66%;
  -webkit-transform: rotate(-38deg);
  transform: rotate(-38deg); }

.cat:hover .cat-hind-foot2 {
  height: 2.7vmin;
  width: 7vmin;
  position: absolute;
  background-color: #092738;
  border-radius: 0% 0% 0% 0%;
  right: 22%;
  bottom: -66%;
  -webkit-transform: rotate(-38deg);
  transform: rotate(-38deg);
  z-index: -1; }

.cat:hover .cat-hind-leg-top {
  border-top: 9vmin solid #0e3d58;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-27deg);
  transform: rotate(-27deg);
  position: absolute;
  top: 89%;
  right: 19%;
  z-index: 5; }

.cat:hover .cat-hind-leg-top2 {
  border-top: 9vmin solid #092738;
  border-left: 3vmin solid transparent;
  height: 0;
  width: 5vmin;
  -webkit-transform: rotate(-27deg);
  transform: rotate(-27deg);
  position: absolute;
  top: 89%;
  right: 23%;
  z-index: -1; }

.cat-tail {
  background-color: transparent;
  height: 12vmin;
  width: 15vmin;
  -moz-border-radius: 2vmin 0 0 16vmin;
  border-left: 2vmin solid #0e3d58;
  border-bottom: 2vmin solid #0e3d58;
  border-radius: 2vmin 0 0 16vmin;
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  right: -30%;
  bottom: -65%;
  position: absolute;
  z-index: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat:hover .cat-tail {
  background-color: transparent;
  height: 20vmin;
  width: 9vmin;
  -moz-border-radius: 2vmin 0 0 16vmin;
  border-left: 2vmin solid #0e3d58;
  border-bottom: 2vmin solid #0e3d58;
  border-radius: 2vmin 0 0 16vmin;
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  right: -18%;
  bottom: -65%;
  position: absolute;
  z-index: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat-tail-end {
  width: 10vmin;
  height: 1.9vmin;
  position: absolute;
  bottom: -65%;
  right: -63%;
  background-color: #bfbfbf;
  border-radius: 0% 25% 25% 0%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cat:hover .cat-tail-end {
  width: 10vmin;
  height: 1.9vmin;
  position: absolute;
  bottom: -65%;
  right: -51%;
  background-color: #bfbfbf;
  border-radius: 0% 25% 25% 0%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cateye {
  position: absolute;
  overflow: hidden;
  width: 9vmin;
  height: 9vmin;
  border-radius: 50%;
  -webkit-transform: scale(0.65);
          transform: scale(0.65);
  bottom: 24.2%; }

.cateye2 {
  position: absolute;
  overflow: hidden;
  width: 9vmin;
  height: 9vmin;
  border-radius: 50%;
  -webkit-transform: scale(0.65);
          transform: scale(0.65);
  bottom: 24.2%;
  left: 43%; }

.cateye.inner {
  position: absolute;
  background: #ffcd66;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  margin: 0;
  left: 1%;
  right: 1%;
  bottom: 1%;
  top: 1%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out; }

.cateye.inner-2 {
  left: 25%;
  right: 25%;
  bottom: 25%;
  top: 25%;
  background: #000;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  height: 4vmin;
  width: 4vmin; }

.cat:hover .cateye.inner-2 {
  height: 2vmin;
  width: 2vmin;
  left: 40%;
  top: 40%;
  -webkit-animation: cateyes 6s ease-in-out 1;
  animation: cateyes 6s ease-in-out 1; }

.cateyelid {
  position: absolute;
  height: 50%;
  left: 0;
  right: 0;
  background: #0e3d58;
  -webkit-transition: margin 0.15s ease;
  transition: margin 0.15s ease; }

.cateyelid.top {
  display: block;
  top: 0;
  border-radius: 200px 200px 0 0; }

.cateyelid.bottom {
  display: block;
  bottom: 0;
  border-radius: 0 0 200px 200px;
  margin-bottom: -50%;
  -webkit-animation: catblink 4s ease-in-out 2s infinite;
  animation: catblink 4s ease-in-out 2s infinite; }

/* The animation code */
@keyframes catblink {
  0% {
    margin-bottom: -50%; }
  3% {
    margin-bottom: 0%; }
  6% {
    margin-bottom: -50%; }
  100% {
    margin-bottom: -50%; } }

@-webkit-keyframes catblink {
  0% {
    margin-bottom: -50%; }
  3% {
    margin-bottom: 0%; }
  6% {
    margin-bottom: -50%; }
  100% {
    margin-bottom: -50%; } }

@keyframes cattwitch {
  0% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  2% {
    -webkit-transform: rotate(20deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(20deg) skewX(-30deg) scale(1, 0.566); }
  4% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  50% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  100% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); } }

@-webkit-keyframes cattwitch {
  0% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  2% {
    -webkit-transform: rotate(20deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(20deg) skewX(-30deg) scale(1, 0.566); }
  4% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  50% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); }
  100% {
    -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.566);
    transform: rotate(30deg) skewX(-30deg) scale(1, 0.566); } }

@keyframes cateyes {
  0% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  18% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  25% {
    height: 2vmin;
    width: 2vmin;
    left: 20%;
    top: 20%; }
  42% {
    height: 2vmin;
    width: 2vmin;
    left: 20%;
    top: 20%; }
  50% {
    height: 2vmin;
    width: 2vmin;
    left: 60%;
    top: 20%; }
  68% {
    height: 2vmin;
    width: 2vmin;
    left: 60%;
    top: 20%; }
  75% {
    height: 2vmin;
    width: 2vmin;
    left: 30%;
    top: 70%; }
  85% {
    height: 2vmin;
    width: 2vmin;
    left: 30%;
    top: 70%; }
  95% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  100% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; } }

@-webkit-keyframes cateyes {
  0% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  20% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  25% {
    height: 2vmin;
    width: 2vmin;
    left: 20%;
    top: 20%; }
  42% {
    height: 2vmin;
    width: 2vmin;
    left: 20%;
    top: 20%; }
  50% {
    height: 2vmin;
    width: 2vmin;
    left: 60%;
    top: 20%; }
  68% {
    height: 2vmin;
    width: 2vmin;
    left: 60%;
    top: 20%; }
  75% {
    height: 2vmin;
    width: 2vmin;
    left: 30%;
    top: 70%; }
  85% {
    height: 2vmin;
    width: 2vmin;
    left: 30%;
    top: 70%; }
  95% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; }
  100% {
    height: 2vmin;
    width: 2vmin;
    left: 40%;
    top: 40%; } }

@keyframes meow {
  0% {
    bottom: 120%; }
  15% {
    bottom: 0%; }
  50% {
    bottom: 120%; }
  100% {
    bottom: 120%; } }

@-webkit-keyframes meow {
  0% {
    bottom: 120%; }
  15% {
    bottom: 0%; }
  50% {
    bottom: 120%; }
  100% {
    bottom: 120%; } }

.cat:hover .cateyelid {
  background: transparent; }

.cat:hover .cat-ear {
  -webkit-transform: rotate(40deg) skewX(-30deg) scale(1, 0.566);
  transform: rotate(40deg) skewX(-30deg) scale(1, 0.566);
  -webkit-animation: none;
          animation: none; }

.cat:hover .cat-ear2 {
  -webkit-transform: rotate(98deg) skewX(-30deg) scale(1, 0.566);
  transform: rotate(98deg) skewX(-30deg) scale(1, 0.566); }

.btndiv {
  height: 100px;
  margin-top: 20px;
  margin-bottom: 10px; }

.btnpop {
  background: #ded7c5;
  position: relative;
  width: 40vmin;
  padding: 15px 40px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 5px solid #7c6211;
  color: #7c6211;
  font-size: 25px;
  vertical-align: middle;
  border-radius: 1px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%); }

.btnpop:hover {
  border-bottom: 5px solid #1462bd;
  background: #4984cc; }

.btnpop:active {
  top: 2px;
  border-bottom: 2px solid #1462bd;
  background: #4984cc;
  box-shadow: none; }

.btnpop:focus {
  outline: 0; }

.meow h1 {
  font-size: 39vmin;
  position: absolute;
  top: -44vmin;
  font-family: ultra;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #1659a9; }

body {
  background-image: url(/static/media/backbrick.53e8bb1f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.winner {
  font-size: 27px;
  -webkit-animation: text-flicker-out-glow 2.5s linear infinite both;
          animation: text-flicker-out-glow 2.5s linear infinite both;
}
.game-container {
  width: 600px;
  background-color: #925652;
  padding: 10px;
  border: 2px solid #452220;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px rgba(69, 34, 32, 1);
  height: 90vh;
  margin-top: 90px;
}
.tictactoe {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.score-board-border {
  background-color: #609275;
  padding: 10px;
  border: 2px solid #274534;
  width: 200px;
  border-radius: 10px;
  height: 85vh;
}

.score-board {
  font-family: "Indie Flower", cursive;
  font-size: 25px;
  color: white;
  background-color: #925652;
  padding: 10px;
  border: 2px solid #452220;
  border-radius: 10px;
  width: 230px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 5px 5px 10px 0px rgba(69, 34, 32, 1);
  text-align: center;
  height: 90vh;
  margin-top: 90px;
}
.btn-reset {
  text-align: center;
  font-size: 30px;
  box-sizing: content-box;
}
.btn-reset:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
}

.choose-player:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.score-board-title {
  text-decoration: underline;
}
.game-info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Indie Flower", cursive;
  font-size: 25px;
}

.mode-title {
  text-decoration: underline;
}
.select-mode {
  display: flex;
  font-size: 20px;
  justify-content: center;
}
.vs-comp {
  margin-left: 5px;
}
.vs-comp:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.vs-player:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.game-board {
  display: flex;
  flex-wrap: wrap;
  height: 81vh;
  background-color: #609275;
  padding: 30px;
  border: 2px solid #274534;
  border-radius: 10px;
}
#field1,
#field4,
#field7 {
  border-right-style: solid;
  border-right-width: thick;
  border-right-color: #57abaa;
  border-left-style: solid;
  border-left-width: thick;
  border-left-color: #57abaa;
}
#field3,
#field5,
#field4 {
  border-top-style: solid;
  border-top-width: thick;
  border-top-color: #57abaa;
  border-bottom-style: solid;
  border-bottom-width: thick;
  border-bottom-color: #57abaa;
}
.game-board--field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  background: #609275;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Indie Flower", cursive;
  font-size: 100px;
  color: white;
}
.game-board--field:hover {
  background: #659260;
}
.game-board--field:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

@-webkit-keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}

@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}

body {
  margin-top: 0px;
  height: 100vh;
  width: 100vw;
  color: white; }

@font-face {
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/terminal_copy.ttf);
  font-family: "Terminal"; }

@font-face {
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/sys.ttf) format("truetype");
  font-family: "Sys"; }

.menu-text {
  -webkit-animation: 5s ease 2000ms normal none infinite running glitch;
          animation: 5s ease 2000ms normal none infinite running glitch;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-animation: jerkup 100ms infinite;
          animation: jerkup 100ms infinite; }

.pause {
  cursor: pointer;
  z-index: 2;
  pointer-events: all;
  display: flex;
  justify-content: center; }

.h5 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 30px; }

.game-board-paddle--full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px; }

.gameover {
  position: absolute;
  color: lime;
  font-size: 80px;
  z-index: 3;
  cursor: pointer;
  -webkit-filter: blur();
          filter: blur();
  -webkit-animation: jerkup 100ms infinite;
          animation: jerkup 100ms infinite;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.full {
  position: absolute;
  z-index: 99;
  right: 50px;
  top: 80px;
  font-size: 30px;
  background: black;
  color: white;
  opacity: 0.7; }

.menu-text:last-child {
  text-align: right; }

.screen::before {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#85908c), to(#323431)) repeat scroll 0 0;
  background: transparent -webkit-linear-gradient(top, #85908c 0%, #323431 100%) repeat scroll 0 0;
  background: transparent linear-gradient(to bottom, #85908c 0%, #323431 100%) repeat scroll 0 0;
  content: "";
  position: absolute;
  left: 50%;
  top: 51%;
  -webkit-transform: translate3d(-50%, -56%, 0);
          transform: translate3d(-50%, -56%, 0);
  width: 100%;
  height: 100%;
  z-index: -1; }

.scanlines .overlay {
  height: 95%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .scanlines .overlay::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/crt.df84d0e7.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: ""; }
  .scanlines .overlay::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(18, 16, 16, 0)), color-stop(50%, rgba(0, 0, 0, 0.25))), -webkit-gradient(linear, left top, right top, from(rgba(255, 0, 0, 0.06)), color-stop(rgba(0, 255, 0, 0.02)), to(rgba(0, 0, 255, 0.06)));
    background: -webkit-linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), -webkit-linear-gradient(left, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%; }

.menu {
  opacity: 1;
  background-color: #0069ff;
  box-sizing: border-box;
  font-family: "Sys";
  font-size: 40px;
  height: auto;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 #000000, -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
  color: #fff; }
  .menu header {
    color: #e7e05c;
    margin-bottom: 10px;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between; }
  .menu footer {
    background-color: #2afd72;
    margin-top: 10px;
    padding: 5px 15px;
    -webkit-animation: 5s ease 2000ms normal none infinite running glitch;
            animation: 5s ease 2000ms normal none infinite running glitch;
    -webkit-filter: blur(1px);
            filter: blur(1px); }
    .menu footer::after {
      clear: both;
      content: " ";
      display: table; }
    .menu footer .key {
      float: left;
      width: 50%; }
      .menu footer .key span {
        background-color: #0069ff;
        border: 6px solid #e7e05c;
        border-radius: 6px;
        display: inline-block;
        height: 34px;
        line-height: 36px;
        padding-left: 6px; }
    .menu footer .key:last-child {
      text-align: right; }

canvas {
  cursor: none;
  z-index: 2;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-animation: jerkup 100ms infinite;
          animation: jerkup 100ms infinite; }

.text {
  color: lime !important;
  opacity: 1;
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
  font-family: Sys;
  font-size: 6vmin;
  left: 5%;
  position: absolute;
  text-shadow: 0 0 3px lime;
  top: 10%;
  width: 200px; }

.on .text,
.on .menu {
  opacity: 1;
  -webkit-transition: opacity 10ms ease 2000ms;
  transition: opacity 10ms ease 2000ms; }

@-webkit-keyframes on {
  0% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
            transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(4);
            filter: brightness(4);
    opacity: 1; }
  3.5% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
            transform: scale(1, 0.8) translate3d(0, 100%, 0); }
  3.6% {
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
            transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1; }
  9% {
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
            transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(4);
            filter: brightness(4);
    opacity: 0; }
  11% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
            transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
            filter: contrast(0) brightness(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
            transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
            filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1; } }

@keyframes on {
  0% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
            transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(4);
            filter: brightness(4);
    opacity: 1; }
  3.5% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
            transform: scale(1, 0.8) translate3d(0, 100%, 0); }
  3.6% {
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
            transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1; }
  9% {
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
            transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(4);
            filter: brightness(4);
    opacity: 0; }
  11% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
            transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
            filter: contrast(0) brightness(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
            transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
            filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1; } }

@-webkit-keyframes off {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-filter: brightness(1);
            filter: brightness(1); }
  40% {
    -webkit-transform: scale(1, 0.005);
            transform: scale(1, 0.005);
    -webkit-filter: brightness(100);
            filter: brightness(100); }
  70% {
    -webkit-transform: scale(1, 0.005);
            transform: scale(1, 0.005); }
  90% {
    -webkit-transform: scale(0.005, 0.005);
            transform: scale(0.005, 0.005); }
  100% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

@keyframes off {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-filter: brightness(1);
            filter: brightness(1); }
  40% {
    -webkit-transform: scale(1, 0.005);
            transform: scale(1, 0.005);
    -webkit-filter: brightness(100);
            filter: brightness(100); }
  70% {
    -webkit-transform: scale(1, 0.005);
            transform: scale(1, 0.005); }
  90% {
    -webkit-transform: scale(0.005, 0.005);
            transform: scale(0.005, 0.005); }
  100% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

@-webkit-keyframes blur {
  0% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 0.8; }
  50% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 1; }
  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 0.8; } }

@keyframes blur {
  0% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 0.8; }
  50% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 1; }
  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    opacity: 0.8; } }

@-webkit-keyframes jerk {
  50% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px); }
  51% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes jerk {
  50% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px); }
  51% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes jerkup {
  0% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes jerkup {
  0% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.text span:nth-child(2) {
  -webkit-animation: jerkup 1s infinite;
          animation: jerkup 1s infinite; }

.text span:nth-child(3) {
  -webkit-animation: glitch1 1s infinite;
          animation: glitch1 1s infinite; }

@-webkit-keyframes glitch1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  31% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); }
  32% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  98% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

@keyframes glitch1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  31% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); }
  32% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  98% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

.text span:nth-child(2) {
  -webkit-animation: glitch2 1s infinite;
          animation: glitch2 1s infinite; }

@-webkit-keyframes glitch2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  31% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  32% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  98% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

@keyframes glitch2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  31% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  32% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  98% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

.game-field {
  display: flex;
  justify-content: center;
  align-items: center; }

.overlay .text {
  -webkit-animation: 5s ease 2000ms normal none infinite running glitch;
          animation: 5s ease 2000ms normal none infinite running glitch; }

@-webkit-keyframes glitch {
  30% { }
  40% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform: skew(0, 0);
            transform: skew(0, 0); }
  41% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1.2);
            transform: scale(1, 1.2);
    -webkit-transform: skew(80deg, 0);
            transform: skew(80deg, 0); }
  42% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1.2);
            transform: scale(1, 1.2);
    -webkit-transform: skew(-50deg, 0);
            transform: skew(-50deg, 0); }
  43% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform: skew(0, 0);
            transform: skew(0, 0); }
  65% { } }

@keyframes glitch {
  30% { }
  40% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform: skew(0, 0);
            transform: skew(0, 0); }
  41% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1.2);
            transform: scale(1, 1.2);
    -webkit-transform: skew(80deg, 0);
            transform: skew(80deg, 0); }
  42% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1.2);
            transform: scale(1, 1.2);
    -webkit-transform: skew(-50deg, 0);
            transform: skew(-50deg, 0); }
  43% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform: skew(0, 0);
            transform: skew(0, 0); }
  65% { } }

.setting {
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.option {
  display: flex;
  align-items: center;

  height: 200px;
}
.option1 {
  display: flex;
  align-items: center;
  height: 100px;
}
h5 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
}
.setting-menu > button {
  flex-shrink: 1;
  border-radius: 50%;
  margin-left: 10px;
  height: 80px;
  width: 80px;
}
.setting-menu > button:hover {
  zoom: 1.2;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  -webkit-transition: 1s;
  transition: 1s;
  border: 2px white solid;
}
.plate {
  position: absolute;
  color: white;
  font-size: 20px;
  background-color: #151c32;
  border-radius: 10px;
  left: 10%;
  top: 30%;
  -webkit-transform: translateX(-30px) rotate(10deg);
          transform: translateX(-30px) rotate(10deg);
  border: 1px grey solid;
  padding: 10px;
  box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.75);
  z-index: 2;
  font-weight: 500;
  -webkit-filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));
          filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));
}
.plate:hover {
  -webkit-animation: wobble-hor-top 1s both;
          animation: wobble-hor-top 1s both;
  cursor: pointer;
  -webkit-transition: 2s;
  transition: 2s;
}
.plate:before {
  position: absolute;
  content: ".";
  height: 10px;
  width: 10px;
  color: grey;
  margin-left: 40%;
  font-size: 80px;
  margin-top: -150px;
}
.plate:after {
  content: "";
  width: 50px;
  height: 70px;
  border: 1px #151c32 solid;
  margin-left: -100px;
  position: absolute;
  margin-top: -69px;
  border-radius: 150px;
  z-index: 1;
}

@-webkit-keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

@keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

.trash {
  background: #151c32;
  width: 180px;
  height: 300px;
  display: inline-block;
  margin: 0 auto;
  position: relative;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  top: 100px;
  right: -500px;
  -webkit-filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));
          filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.9));

  box-shadow: inset 0 0 1px #000000;
  z-index: 2;
}
.trash:after {
  content: "";
  bottom: 0px;
  width: 100%;
  left: 50%;
  margin-left: -40%;
  -webkit-transform: perspective(300px) rotateY(40deg) rotateX(50deg);
  transform: perspective(300px) rotateY(40deg) rotateX(50deg);
}

.blackhole {
  position: absolute;
  height: 20px;
  width: 100%;
  background: black;
  border-radius: 50%;
  top: -10px;

  z-index: -1;
}
.pokrywka {
  position: absolute;
  height: 29px;
  background: #151c32;
  top: -19px;
  left: -10px;
  right: -10px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform-origin: 19% 100%;
          transform-origin: 19% 100%;
}
.pokrywka:after {
  content: "";
  position: absolute;
  height: 17px;
  background: #151c32;
  width: 160px;
  top: -15px;
  left: 20px;
  right: 30px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform-origin: 19% 100%;
          transform-origin: 19% 100%;
}
.pokrywka:before {
  content: "";
  position: absolute;
  height: 20px;
  background: #1c2542;
  width: 20px;
  top: -28px;
  left: 90px;
  right: 80px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform-origin: 19% 100%;
          transform-origin: 19% 100%;
  box-shadow: inset 0 0 1px #000000;
}
.trash:hover .pokrywka {
  -webkit-transform: rotate(-95deg);
          transform: rotate(-95deg);
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-animation-play-state: smell running;
          animation-play-state: smell running;
}

.paski {
  position: relative;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  margin: 25px auto;
  border-radius: 5px;
  box-shadow: inset 0 0 5px #1a223d;
}
.paski:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  border-radius: 5px;
  left: -50px;
  box-shadow: inset 0 0 5px #1a223d;
}
.paski:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 250px;
  background: #1c2542;
  display: block;
  border-radius: 5px;
  right: -50px;
  box-shadow: inset 0 0 5px #1a223d;
}

#smells {
  display: none;
  border-radius: 50%;

  -webkit-animation: smell 5s ease-in-out infinite;

          animation: smell 5s ease-in-out infinite;
  background-image: -webkit-radial-gradient(
    ellipse at center center,
    rgba(65, 64, 64, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: -webkit-radial-gradient(
    center center,
    ellipse,
    rgba(33, 33, 33, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: -webkit-radial-gradient(
    center center,
    ellipse,
    rgba(4, 51, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: radial-gradient(
    ellipse at center center,
    rgba(4, 51, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  position: absolute;
  top: -45%;
  left: -30%;
  width: 300px;
  height: 300px;
  z-index: -2;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#smells:before {
  border-radius: 50%;
  -webkit-transform: scale(1.6);
  transform: scale(2.6);
  background-image: -webkit-radial-gradient(
    center center,
    ellipse,
    rgba(33, 33, 33, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-image: radial-gradient(
    ellipse at center center,
    rgba(33, 33, 33, 0.3) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 180px;
  height: 100px;
  z-index: -5;
}

@-webkit-keyframes smell {
  0% {
    -webkit-transform: scale(0.5) translateY(0);
            transform: scale(0.5) translateY(0);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5) translateY(-300px);
            transform: scale(1.5) translateY(-300px);
    opacity: 0;
  }
}

@keyframes smell {
  0% {
    -webkit-transform: scale(0.5) translateY(0);
            transform: scale(0.5) translateY(0);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5) translateY(-300px);
            transform: scale(1.5) translateY(-300px);
    opacity: 0;
  }
}
.fly {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-animation: fly 10s ease-in-out both;
          animation: fly 10s ease-in-out both;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;

  z-index: 90;
}
.fly:before {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly:after {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly:hover {
  cursor: pointer;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes fly {
  0% {
    -webkit-transform: rotate(-90deg) translateY(0);
            transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  10% {
    -webkit-transform: rotate(-45deg) translateY(-10px);
            transform: rotate(-45deg) translateY(-10px);
    opacity: 1;
  }
  20% {
    -webkit-transform: rotate(-53deg) translateY(-10px);
            transform: rotate(-53deg) translateY(-10px);
    opacity: 1;
  }
  30% {
    -webkit-transform: rotate(-51deg) translateY(-10px);
            transform: rotate(-51deg) translateY(-10px);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate(-48deg) translateY(-10px);
            transform: rotate(-48deg) translateY(-10px);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(-42deg) translateY(-10px);
            transform: rotate(-42deg) translateY(-10px);
    opacity: 1;
  }
  60% {
    -webkit-transform: rotate(-52deg) translateY(-10px);
            transform: rotate(-52deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-80deg) translateY(-2000px);
            transform: rotate(-80deg) translateY(-2000px);
    opacity: 1;
  }
}

@keyframes fly {
  0% {
    -webkit-transform: rotate(-90deg) translateY(0);
            transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  10% {
    -webkit-transform: rotate(-45deg) translateY(-10px);
            transform: rotate(-45deg) translateY(-10px);
    opacity: 1;
  }
  20% {
    -webkit-transform: rotate(-53deg) translateY(-10px);
            transform: rotate(-53deg) translateY(-10px);
    opacity: 1;
  }
  30% {
    -webkit-transform: rotate(-51deg) translateY(-10px);
            transform: rotate(-51deg) translateY(-10px);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate(-48deg) translateY(-10px);
            transform: rotate(-48deg) translateY(-10px);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(-42deg) translateY(-10px);
            transform: rotate(-42deg) translateY(-10px);
    opacity: 1;
  }
  60% {
    -webkit-transform: rotate(-52deg) translateY(-10px);
            transform: rotate(-52deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-80deg) translateY(-2000px);
            transform: rotate(-80deg) translateY(-2000px);
    opacity: 1;
  }
}

.fly1 {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 10%;
  -webkit-animation: fly1 1s ease-in-out both;
          animation: fly1 1s ease-in-out both;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;

  z-index: 2;
}
.fly1:before {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly1:after {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly1:hover {
  cursor: pointer;
}

@-webkit-keyframes fly1 {
  0% {
    -webkit-transform: rotate(-90deg) translateY(-10px);
            transform: rotate(-90deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-90deg) translateY(-400px);
            transform: rotate(-90deg) translateY(-400px);
    opacity: 1;
  }
}

@keyframes fly1 {
  0% {
    -webkit-transform: rotate(-90deg) translateY(-10px);
            transform: rotate(-90deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-90deg) translateY(-400px);
            transform: rotate(-90deg) translateY(-400px);
    opacity: 1;
  }
}
.fly-hole {
  height: 10px;
  width: 5px;
  border-radius: 50%;
  background: black;
  position: absolute;
  left: 50%;
  top: 20%;

  -webkit-transition: -webkit-transform 250ms;

  transition: -webkit-transform 250ms;

  transition: transform 250ms;

  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: 10% 100%;
          transform-origin: 10% 100%;

  z-index: 90;
}
.fly-hole:before {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  right: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly-hole:after {
  content: "";
  height: 8px;
  width: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -4px;
  bottom: -1px;
  opacity: 0.5;
}
.fly-hole:hover {
  cursor: pointer;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes fly-hole {
  0% {
    -webkit-transform: rotate(-90deg) translateY(0);
            transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  70% {
    -webkit-transform: rotate(90deg) translateY(-250px);
            transform: rotate(90deg) translateY(-250px);
    opacity: 1;
  }
}

@keyframes fly-hole {
  0% {
    -webkit-transform: rotate(-90deg) translateY(0);
            transform: rotate(-90deg) translateY(0);
    opacity: 1;
  }
  70% {
    -webkit-transform: rotate(90deg) translateY(-250px);
            transform: rotate(90deg) translateY(-250px);
    opacity: 1;
  }
}

