@import url("https://fonts.googleapis.com/css?family=Indie+Flower");
@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  background-image: url("../backbrick.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.winner {
  font-size: 27px;
  animation: text-flicker-out-glow 2.5s linear infinite both;
}
.game-container {
  width: 600px;
  background-color: #925652;
  padding: 10px;
  border: 2px solid #452220;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px rgba(69, 34, 32, 1);
  height: 90vh;
  margin-top: 90px;
}
.tictactoe {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.score-board-border {
  background-color: #609275;
  padding: 10px;
  border: 2px solid #274534;
  width: 200px;
  border-radius: 10px;
  height: 85vh;
}

.score-board {
  font-family: "Indie Flower", cursive;
  font-size: 25px;
  color: white;
  background-color: #925652;
  padding: 10px;
  border: 2px solid #452220;
  border-radius: 10px;
  width: 230px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 5px 5px 10px 0px rgba(69, 34, 32, 1);
  text-align: center;
  height: 90vh;
  margin-top: 90px;
}
.btn-reset {
  text-align: center;
  font-size: 30px;
  box-sizing: content-box;
}
.btn-reset:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
}

.choose-player:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.score-board-title {
  text-decoration: underline;
}
.game-info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Indie Flower", cursive;
  font-size: 25px;
}

.mode-title {
  text-decoration: underline;
}
.select-mode {
  display: flex;
  font-size: 20px;
  justify-content: center;
}
.vs-comp {
  margin-left: 5px;
}
.vs-comp:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.vs-player:hover {
  cursor: pointer;
  border: 3px #57abaa solid;
  border-radius: 50%;
  zoom: 1.1;
}
.game-board {
  display: flex;
  flex-wrap: wrap;
  height: 81vh;
  background-color: #609275;
  padding: 30px;
  border: 2px solid #274534;
  border-radius: 10px;
}
#field1,
#field4,
#field7 {
  border-right-style: solid;
  border-right-width: thick;
  border-right-color: #57abaa;
  border-left-style: solid;
  border-left-width: thick;
  border-left-color: #57abaa;
}
#field3,
#field5,
#field4 {
  border-top-style: solid;
  border-top-width: thick;
  border-top-color: #57abaa;
  border-bottom-style: solid;
  border-bottom-width: thick;
  border-bottom-color: #57abaa;
}
.game-board--field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  background: #609275;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Indie Flower", cursive;
  font-size: 100px;
  color: white;
}
.game-board--field:hover {
  background: #659260;
}
.game-board--field:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
